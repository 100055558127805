import React from "react"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import CallToAction from "../components/section/CallToAction/callToAction"

const AvisoLegalPage = () => {

    return (
        <Layout>
            <SEO
                title="Aviso Legal"
                description="Accede a tos los contenidos de tu agencia de marketing digital especializada en posicionamiento web, SEM, diseño web, diseño gráfico, producción gráfica y producción audiovisual."
            />

            <BannerPage
                title="Aviso Legal"
                text="Siempre cumpliendo con la legislación actual"
                color="green"
            />

            <section className="container space-2-bottom space-3-bottom--lg">
                <div className="row">
                    <div className="col-12">
                        <p>
                            IBX DIGITAL SOLUTIONS S.L le garantiza que todos los datos que nos aporte serán tratados con absoluta confidencialidad y total respeto a la vigente Ley Orgánica de Protección de Datos. Toda la documentación y datos que nos facilite serán transferidos al propio personal de IBX DIGITAL SOLUTIONS S.L y otras entidades colaboradoras con el único objetivo de ser analizados. En ningún caso y bajo ningún concepto serán manipulados ni vendidos a terceros.<br /><br />
                            IBX DIGITAL SOLUTIONS S.L sólo le solicitará la documentación que sea estrictamente necesaria para la autorización del servicio que nos solicite. IBX DIGITAL SOLUTIONS S.L, una vez cerrada la solicitud de nuestros servicios, tiene el derecho de guardar la documentación que nos aporte para poder realizar estudios de mercado y estadísticas, así como para poder llamarle en un futuro y ofrecerle nuevos productos y/o servicios.<br /><br />
                            En cualquier momento usted podrá ejercer su derecho de Acceso, Rectificación y Cancelación de los datos facilitados. Dichos actos deberá realizarlos ante cualquier trabajador del equipo de análisis de nuestra empresa. <br /><br />
                            <strong>CONDICIONES GENERALES DE CONTRATACIÓN.</strong> <br /><br />
                            Este documento establece las condiciones generales de contratación de la empresa IBX DIGITAL SOLUTIONS S.L. Las condiciones generales de contratación regulan la relación contractual entre IBX DIGITAL SOLUTIONS S.L y las personas físicas y/o jurídicas que suscriban con la citada empresa el correspondiente contrato de intermediación para la gestión de servicios.<br /><br />
                            <strong>1.-</strong>  IBX DIGITAL SOLUTIONS S.L, con CIF número B-02911493, y domicilio en Bisbe Iglesies 58, Piso 3 25620 Tremp (Lleida). Su trabajo consiste en asesorar a empresas online y gestionar y posicionar su marca en la red mediante el uso de distintas técnicas y estrategias (posicionamiento web, diseño web, e-marketing, auditoría y asesoramiento online, analítica web, social media &amp; Community Manger) realizando su labor comercial a través de publicidad  en la red. Con el fin de conseguir los productos que mejor se adapten a las necesidades de cada uno de nuestros clientes.<br /><br />
                            <strong>2.-</strong>El precio total que LOS CLIENTES deberán abonar a IBX DIGITAL SOLUTIONS S.L  por su labor, será el importe acordado en el contrato firmado antes de empezar cualquier prestación de servicio. La forma de pago se establecerá en el momento de la firma del contrato.<br /><br />
                            <strong>3.-</strong>IBX DIGITAL SOLUTIONS S.L dispone de la página web www.ibxagency.com<br /><br />
                            <strong>4.-</strong>En cumplimiento de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, y en el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la LOPD, y de cualesquiera otras normas vigentes o que en el futuro vengan a sustituir y/o a modificar a éstas, se aplicará lo siguiente:<br /><br />
                            a) Los datos de carácter personal facilitados por LOS CLIENTES quedarán registrados en el fichero de IBX DIGITAL SOLUTIONS S.L, inscrito en el Registro General de Protección de Datos. Estos datos sólo serán accesibles al personal de esta entidad, y serán cedidos única y exclusivamente a las entidades con las que IBX DIGITAL SOLUTIONS S.L colabora.<br /><br />
                            b) Siempre que sea necesario acceder a cualquier información legal, IBX DIGITAL SOLUTIONS S.L, pedirá autorización a sus CLIENTES. <br /><br />
                            c) En cualquier momento, LOS CLIENTES podrán ejercer su derecho de Acceso, Rectificación, Oposición y Cancelación de los datos que ha facilitado a IBX DIGITAL SOLUTIONS S.L. El ejercicio de este derecho deberán realizarlo ante el responsable del fichero, mediante comunicación escrita dirigida a: IBX DIGITAL SOLUTIONS S.L, Bisbe Iglesies 58, Piso 3 25620 Tremp (Lleida). <br /><br />
                        </p>
                    </div>
                </div>
            </section>


            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text="Somos tu Agencia de Marketing Digital"
                button="Pide presupuesto ¡Gratis!"
                buttonUrl="/contacto"
            />



        </Layout>
    )

}
export default AvisoLegalPage
